<template>
  <section class="select-products-block">
    <div class="container">
      <div class="select-products-block__head">
        <h2
          v-if="values.title"
          class="select-products-block__title"
          v-html="values.title"
        />
        <NuxtLink :to="values?.link || ''">
          {{ values?.link_title || '' }}
          <img
            src="~/assets/img/svg/common/arrow.svg"
            alt="стрелка"
          >
        </NuxtLink>
      </div>
      <div
        v-if="descriptionFromValues"
        v-html="descriptionFromValues"
        class="select-product-block__description"
      >
      </div>
      <div>
        <div
          v-if="$viewport.isGreaterOrEquals('lg')"
          class="select-products-block__body"
          :class="{
            'products': !isProductsCollection,
            'collection': isProductsCollection,
          }"
        >
          <div
            v-for="(product) in products"
            :key="`product-${product.id}`"
          >
            <CollectionCard
              v-if="Boolean(product.characteristicGroupId === 19)"
              :product="product"
            />
            <ProductCard
              v-else
              :product="product"
            />
          </div>
          <div ref="sectionProducts"/>
        </div>
        <MobileSwiper
          v-else
          :key="`MobileSwiper-${ keyMobileSwiper }`"
          :products="products"
        />
      </div>
      <NuxtLink
        v-if="values.link_title"
        class="btn btn-outline select-products__btn"
        :to="values?.link || ''"
      >
        {{ values?.link_title || '' }}
      </NuxtLink>
    </div>
  </section>
</template>

<script lang="ts" setup>
import type { IBlockDefaultProps } from '#sitis/internal/models/common';
import type { IProduct } from '#sitis/internal/controllers/products/models/Product';
import { Product } from '#sitis/internal/controllers/products/models/Product';
import { useNuxtApp, computed, ref } from '#imports';
import ProductCard from '~/components/catalog/ProductCard.vue';
import CollectionCard from '~/components/catalog/CollectionCard.vue';
import generateId from '~/utils/generateId';
import MobileSwiper from "~/components/select-products-block/MobileSwiper.vue";
import useEcommerce from "~/composables/useEcommerceYandex";
import {useIntersectionObserver} from "@vueuse/core";

// const MobileSwiper = defineAsyncComponent(() => import(''));

const props = defineProps<IBlockDefaultProps>();
const {
  $api,
  $viewport
} = useNuxtApp();

const { addEcommerceProductsList } = useEcommerce();

const products = ref<IProduct[]>([]);
const isProductsCollection = computed(() => Boolean(products.value?.[0]?.characteristicGroupId === 19));

const getProducts = async () => {
  const apiUrl = (props?.extras?.products_endpoint || '').replace('/api', '');
  let res = await $api.agent
    .get(apiUrl)
    .then((res: any) => res._data)
    .catch(() => []);

  res = res.map((product: any) => new Product(product));
  products.value = res;
  // addEcommerceProductsList(products.value, `${props.values.title}` || '' )
};

await Promise.all([
  await getProducts(),
]);

const descriptionFromValues = computed(() => {
  if ($viewport.isLessThan('lg')) {
    return props.values.description_mobile;
  }
  return props.values.description_desktop;
});

const keyMobileSwiper: Ref<string> = ref(String(generateId(3)));

const isSendingProducts: Ref<boolean> = ref(false);
const sectionProducts = ref<HTMLDivElement | null>();
const sendingProductsTo = async () => {
  isSendingProducts.value = true;
  addEcommerceProductsList(products.value, `${props?.values?.title}`);
}
const { stop } = useIntersectionObserver(
  sectionProducts,
  async ([{ isIntersecting }]) => {
    if (isIntersecting && !isSendingProducts.value) {
      await sendingProductsTo();
    }
  },
  {

  }
);
</script>

<style lang="scss" scoped>
.select-products-block {
	padding: 82px 0;
	box-sizing: border-box;
	background-color: white;
	position: relative;
	z-index: 1;
	border-radius: 20px 20px 0 0;
}

.select-products__btn {
	display: none;
}

.select-products-block__head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 32px;

	& > a {
		display: flex;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
		align-items: center;
		color: var(--black);
		gap: 6px;

		&:hover {
			opacity: .7;
		}
	}

	& img {
		transform: rotate(180deg);
		width: 14px;
		height: 14px;
	}
}

.select-product-block__description {
  font-weight: 500;
  line-height: 130%;
  padding-bottom: 32px;
}

.select-products-block__body {
	display: flex;
	flex-wrap: wrap;
	margin-top: -32px;
	margin-left: -32px;

	& > * {
		margin-top: 32px;
		margin-left: 32px;
	}

	&.collection {
		& > * {
			width: calc(100% / 3 - 32px);
		}
	}

	&.products {
		& > * {
			width: calc(100% / 4 - 32px);
		}
	}
}

@media (max-width: 1699px) {
	.select-products-block__body.collection {
		& > * {
			width: calc(50% - 32px);
		}
	}
	.select-products-block__body.products {
		& > * {
			width: calc(100% / 3 - 32px);
		}
	}
}

@media (max-width: 1359px) {
  .select-products-block__body.products {
    & > * {
      width: calc(100% / 2 - 32px);
    }
  }
}

@media (max-width: 640px) {
	.select-products-block__head {
		& > a {
			display: none;
		}
	}
	.select-products__btn {
		padding: 15px 24px;
		margin-top: 32px;
		display: flex;
		border-color: var(--black);
		font-weight: 600;
	}
}
</style>
